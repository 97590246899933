footer {
	margin-top: auto;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	.container {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 16px;
		@include media-breakpoint-up (md) {
			padding: 32px;
		}

		.image-container {
			width: 240px;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		.text-container {
			p {
				margin-bottom: 0;
				font-size: rem-calc(13);
				@include media-breakpoint-up (md) {
					font-size: rem-calc(15);
				}
			}			
		}

		> a {
			font-size: rem-calc(15);
			@include media-breakpoint-up (md) {
				font-size: rem-calc(17);
			}
		}

		.logo-container {
			display: flex;
			align-items: center;
			gap: 24px;
			list-style: none;
			margin: 16px 0 0 0;
			padding: 16px 0 0 0;
			border-top: solid 1px rgba($white, 0.1);
			width: 100vw;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			@include media-breakpoint-up (md) {
				gap: 30px;
				margin-top: 32px;
				padding-top: 32px;
			}

			li {
				a {
					display: block;
					img {
						height: 20px;
						object-fit: contain;		
						transition: all 0.3s ease-in-out;
						@include media-breakpoint-up (md) {
							height: 24px;
						}		

						&:hover {
							filter: brightness(2);
						}
					}
				}
			}

			&.small-logos {
				width: 100vw;
				display: flex;
				align-items: center;
				justify-content: center;
				padding-top: 16px;
				margin-top: 32px;
				border-top: solid 1px rgba($white, 0.1);
				@include media-breakpoint-up (md) {
					padding-top: 32px;
					margin-top: 64px;
				}

				li {
					a {
						img {
							height: 26px;
							@include media-breakpoint-up (md) {
								height: 32px;
							}
						}
					}
				}
			}
		}
	}
}