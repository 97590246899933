.content-container {
	display: flex;
	flex-direction: column;
	align-items: center;

	.logo-container {
		width: 100%;
		max-width: 140px;
		@include media-breakpoint-up (md) {
			max-width: 200px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.text-container {
		font-size: rem-calc(18);
		text-align: center;
		@include media-breakpoint-up (md) {
			font-size: rem-calc(20);
		}

		p {
			margin-bottom: 0;
		}
	}

	.small {
		display: block;
		font-size: rem-calc(15);
		text-align: center;
		@include media-breakpoint-up (md) {
			font-size: rem-calc(17);
		}
	}
}