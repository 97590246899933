body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    > .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
    	padding: 48px 16px;
    	@include media-breakpoint-up (md) {
    		padding: 64px 32px;
    	}
    }

    a {
        transition: all 0.3s ease-in-out;
    }
}